import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { mercadoPago } from "services/Payments";
import { useMercadopago } from "react-sdk-mercadopago/lib";
import "./pay.css";

const apiMercadoPago = process.env.REACT_APP_API_KEY_MERCADOPAGO;
localStorage.setItem("startp", 1);

const Payment = ({ mora, user, setFormConfig }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      tipo: user.tipo,
      contrato: user.contrato,
      codigo: user.codigo,
    },
  });
  const mercadopago = useMercadopago.v2(apiMercadoPago, { locale: "es-CO" });
  const [codMercado, setcodMercado] = useState(null);

  // Formatear valor de dinero
  const formatterMoney = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const OnSubmit = handleSubmit(async (data) => {
    try {
      const response = await mercadoPago(data);
      setcodMercado(response.data);
    } catch (error) {
      console.error("Error al crear la preferencia de pago:", error);
      alert("Se ha generado un error al crear el id de mercado pago", error);
    }
  });

  useEffect(() => {
    const render_btn_mercado = async () => {
      if (mercadopago && codMercado) {
        try {
          const response = await mercadopago.checkout({
            preference: {
              id: codMercado,
            },
            render: {
              container: ".cho-container",
              label: "Pagar con MercadoPago",
            },
            autoOpen: true,
          });
          console.log("Respuesta del método checkout:", response);
          // Verificar si la respuesta indica un error
          if (response.status === "failure") {
            console.error("Error al procesar el pago:", response);
            alert(
              "Hubo un problema al procesar el pago. Por favor, inténtalo de nuevo."
            );
          }
        } catch (error) {
          console.error("Error al llamar a checkout:", error);
          alert(
            "Se produjo un error al iniciar el proceso de pago. Por favor, inténtalo de nuevo."
          );
        }
      }
    };

    if (codMercado !== null) {
      setTimeout(() => {
        render_btn_mercado();
      }, 200);
    }
  }, [codMercado, mercadopago]);

  return (
    <main>
      {codMercado && <div className="cho-container" />}
      <form onSubmit={OnSubmit}>
        <div className="centrar mt-4">
          <img
            loading="lazy"
            src="/images/logo/logo-mercado-pago.svg"
            alt="mercado pago"
            width="180"
            height="50"
          />
        </div>
        <article
          className="text-back"
          onClick={() => setFormConfig({ step: 2 })}
        >
          <i className="fa-solid fa-arrow-left"></i>
          Volver
        </article>
        <label htmlFor="concepto">Concepto</label>
        <input
          className="inputPay"
          type="text"
          id="concepto"
          readOnly
          {...register("tipo")}
        />
        <label htmlFor="contrato">Contrato</label>
        <input
          className="inputPay"
          type="text"
          name="contrato"
          id="contrato"
          readOnly
          {...register("contrato")}
        />
        {user.tipo !== "Reservas" && (
          <>
            {user.mora === "1" && (
              <p className="text-danger">
                {`Tu plan vacacional presenta mora desde: ${mora?.fecha_mora}`}
              </p>
            )}
            <div className="radio-inputs mt-4 mb-3">
              {mora?.pago_al_dia && (
                <label>
                  <input
                    className="radio-input"
                    type="radio"
                    name="engine"
                    onClick={() => setValue("valor", mora?.pago_al_dia || 0)}
                  />
                  <span className="radio-tile">
                    <span className="radio-label">
                      Pago para estar al día:{" "}
                      {formatterMoney.format(mora?.pago_al_dia || 0)}
                    </span>
                  </span>
                </label>
              )}
              <label>
                <input
                  className="radio-input"
                  type="radio"
                  name="engine"
                  onClick={() =>
                    setValue("valor", mora?.pago_total || user.saldo)
                  }
                />
                <span className="radio-tile">
                  <span className="radio-label">
                    Pagar saldo:{" "}
                    {formatterMoney.format(mora?.pago_total || user.saldo)}
                  </span>
                </span>
              </label>
            </div>
          </>
        )}
        <div className={errors?.valor && "ErrorContainer"}>
          <label htmlFor="valor">Valor</label>
          <input
            className="inputPay"
            type="number"
            name="valor"
            id="valor"
            {...register("valor", {
              valueAsNumber: true,
              required: "Debe digitar un valor a pagar",
              min: {
                value: 1000,
                message: "El valor mínimo debe ser de 10.000",
              },
            })}
          />
          {errors?.valor && (
            <span className="errorForm">
              <i className="fa-solid fa-circle-exclamation"></i>
              {errors?.valor.message}
            </span>
          )}
        </div>

        <button disabled={isSubmitting} className="MercadoPago mt-3">
          {isSubmitting ? (
            <span className="spinner-border spinner-border-sm"></span>
          ) : (
            "Pagar"
          )}
        </button>
      </form>
    </main>
  );
};

export default Payment;
