import React from "react";

const Sagrilaft = () => {
  return (
    <section
      style={{
        margin: "10px 10%",
        width: "80%",
      }}
    >
      <img
        src="/images/sagrilaft/page1.jpg"
        alt=""
        onContextMenu={(e) => e.preventDefault()}
        onDragStart={(e) => e.preventDefault()}
        className="img-fluid"
      />
      <img
        src="/images/sagrilaft/page1.jpg"
        alt=""
        onContextMenu={(e) => e.preventDefault()}
        onDragStart={(e) => e.preventDefault()}
        className="img-fluid"
      />
    </section>
  );
};

export default Sagrilaft;
