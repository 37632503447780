import React from "react";
import SwiperCore, { Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { A11y, Autoplay, Pagination } from "swiper";
SwiperCore.use([Navigation]);

const SlideImages = ({ images }) => {
  return (
    <div className="container">
      <Swiper
        modules={[Navigation, Autoplay, Pagination, A11y]}
        navigation
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        direction="horizontal"
        pagination={{ clickable: true }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {images?.map((s, index) => (
          <SwiperSlide key={index}>
            <img
              loading="lazy"
              src={s.img}
              alt={s.img}
              key={index}
              className="img-fluid"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SlideImages;
