import React, { useEffect } from "react";

export const Popupall = () => {
  useEffect(() => {
    const popup = localStorage.getItem("startp");
    if (!popup) {
      const modal = new window.bootstrap.Modal(
        document.getElementById("popupmodal")
      );
      modal.show();
      localStorage.setItem("startp", 1);
    }
  }, []);

  return (
    <div className="modal fade" id="popupmodal" role="dialog" tabIndex="-1">
      <div className="modal-dialog modal-lg">
        <div className="modal-content Modalcontentmod">
          <div className="modal-body">
            <a href="https://vip.grupoalianzacolombia.com/" target="blank">
              <img
                loading="lazy"
                src="/images/new-pop.png"
                alt=""
                style={{
                  margin: "10% auto",
                  display: "flex",
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
