import React from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { getValueMora } from "services/Payments";
import { getUserInfo } from "services/Payments";

const ValidateUser = ({ setFormConfig, setUser, setMora }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const OnSubmit = handleSubmit(async (data) => {
    try {
      const response = await getUserInfo(data);
      if (response.status === 500) {
        return toast.error(
          "Usuario no encontrado, por favor intetelo de nuevo"
        );
      }
      setUser(response);
      const resp = await getValueMora(response.codigo);
      setMora(resp);
      setFormConfig({ step: 2 });
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <form onSubmit={OnSubmit}>
      <Toaster />
      <div className={errors?.cedula && "ErrorContainer"}>
        <label htmlFor="cedula">Ingrese su número de cédula</label>
        <input
          type="number"
          className="inputPay"
          {...register("cedula", {
            required: {
              value: true,
              message: "Debe ingresar su número de cédula",
            },
          })}
        />
        {errors?.cedula && (
          <span className="errorForm">
            <i className="fa-solid fa-circle-exclamation"></i>
            {errors?.cedula.message}
          </span>
        )}
      </div>

      <button disabled={isSubmitting} className="MercadoPago mt-3">
        {isSubmitting ? (
          <span className="spinner-border spinner-border-sm"></span>
        ) : (
          "Consultar"
        )}
      </button>
    </form>
  );
};

export default ValidateUser;
