import React, { useState, useRef } from "react";
import SwiperCore, { Navigation } from "swiper/core";
import categoriesGAC from "assets/Categorias.json";
import "swiper/swiper-bundle.min.css";
import "styles/categories.css";
import DestinationsTop from "./DestinationsTop";
SwiperCore.use([Navigation]);

const Categories = () => {
  const [opcionSeleccionada, setOpcionSeleccionada] = useState(
    categoriesGAC.categoriesGAC[0]
  );

  const destinationsTopRef = useRef(null);

  const handleChangeCategorie = (e, category) => {
    e.preventDefault();
    setOpcionSeleccionada(category);
  };

  const scrollToDestinationsTop = () => {
    if (destinationsTopRef.current) {
      destinationsTopRef.current.scrollToTop();
    }
  };

  return (
    <section
      style={{
        position: "relative",
      }}
      id="add_bg"
    >
      <section className="centrar">
        <p className="categorias">CATEGORIAS</p>
      </section>
      <div className="bg">
        <div className="categories-container">
          {categoriesGAC.categoriesGAC.map((c, index) => (
            <div className="" key={index}>
              <div
                className={
                  opcionSeleccionada?.title === c.title
                    ? "nuestrocontenedor-active"
                    : "nuestrocontenedor"
                }
                onClick={(e) => handleChangeCategorie(e, c)}
              >
                <img
                  loading="lazy"
                  src={c.img}
                  alt={c.title}
                  className="img-categories nuestraimagen"
                />
                <div className="nuestrobloque">
                  <div className="nuestrotexto">{c.title}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container pt-3">
        <div className="grid-container" id="destinos">
          <div className="item3">
            <h3 className="title-policy fw-bold">{opcionSeleccionada.title}</h3>
            <p className="justify">{opcionSeleccionada.parrafo}</p>
            <button className="btn-ver" onClick={scrollToDestinationsTop}>
              Ver más
            </button>
          </div>
          {opcionSeleccionada.imagenes.map((imagen, index) => (
            <div
              className={`item${
                index === opcionSeleccionada.imagenes.length - 2
                  ? 4
                  : index === opcionSeleccionada.imagenes.length - 1
                  ? 5
                  : index + 1
              }`}
              key={index}
            >
              <img
                loading="lazy"
                src={imagen}
                alt={`Imagen ${index + 1}`}
                className="br-1"
                width={
                  index === opcionSeleccionada.imagenes.length - 2
                    ? 200
                    : undefined
                }
                height={
                  index === opcionSeleccionada.imagenes.length - 2
                    ? 200
                    : undefined
                }
              />
            </div>
          ))}
        </div>
      </div>
      <DestinationsTop ref={destinationsTopRef} />
    </section>
  );
};

export default Categories;
