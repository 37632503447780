import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowGAC } from "assets/icons/arrow-gac.svg";
import "styles/slider.css";

const Slider = ({
  children,
  showIndicators = true,
  showButtons = true,
  showAlternButtons = false,
  arrowColor = "#fff",
  arrowColorActive = "#ffcf04",
  colorIndicator = "#fff",
  position = "absolute",
  positionIndicators = "10px",
  totalSlidesSend = 0,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const totalSlides =
    totalSlidesSend > 0 ? totalSlidesSend : React.Children.count(children);

  useEffect(() => {
    if (totalSlides > 0 && currentIndex >= totalSlides) {
      setCurrentIndex(0);
    }
  }, [totalSlides, currentIndex]);

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="slider-container">
      <div
        className="slider-content"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, index) => (
          <div
            className={`slide ${index === currentIndex ? "active" : ""}`}
            key={index}
          >
            {child}
          </div>
        ))}
      </div>
      {showAlternButtons && (
        <>
          <ArrowGAC
            onClick={goToPrevSlide}
            className="alternative-btn prev"
            style={{ transform: "rotate(180deg)" }}
          />
          <ArrowGAC onClick={goToNextSlide} className="alternative-btn next" />
        </>
      )}
      {showIndicators && (
        <div
          className="slider-indicators-wrapper"
          style={{
            "--position": `${position}`,
            "--positionIndicators": `${positionIndicators}`,
          }}
        >
          {showButtons && (
            <ArrowGAC
              onClick={goToPrevSlide}
              className="indicator-button"
              style={{ transform: "rotate(180deg)", color: arrowColor }}
            />
          )}
          <div className="slider-indicators">
            {Array.from({ length: totalSlides }).map((_, index) => (
              <div
                key={index}
                className={`indicator ${
                  index === currentIndex ? "active" : ""
                }`}
                style={{
                  "--colorIndicator": `${colorIndicator}`,
                  "--colorIndicatorActive": `${arrowColorActive}`,
                }}
                onClick={() => goToSlide(index)}
              ></div>
            ))}
          </div>
          {showButtons && (
            <ArrowGAC
              onClick={goToNextSlide}
              className="indicator-button"
              style={{ color: arrowColor }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Slider;
