import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import hoteles from "assets/HotelesNacionales.json";
import SwiperCore, { Navigation } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Autoplay, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "styles/categories.css";
import "styles/hotel.css";
import useSendEventGA from "../hooks/useSendEventGA";

SwiperCore.use([Navigation]);

const Hotel = () => {
  const { destino, hotel } = useParams();
  const [selectedHotel, setSelectedHotel] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const selected = hoteles.HotelesNacionales.filter(
      (h) => h.Destino === destino && h.Hotel === hotel
    );
    setSelectedHotel(selected[0]);
  }, [destino, hotel]);

  const SendEvent = (e) => {
    e.preventDefault();
    window.open("https://wa.link/9wpb4o", "_blank");
    useSendEventGA("View Hotel", "Click en Reserva");
  };

  return (
    <>
      <div className="">
        {selectedHotel && (
          <>
            <div className="contenedor-relative">
              <Swiper
                modules={[Autoplay]}
                navigation
                slidesPerView={1}
                spaceBetween={0}
                loop={true}
                direction="horizontal"
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
              >
                {selectedHotel.slider?.map((s, index) => (
                  <SwiperSlide className="" key={index}>
                    <img
                      loading="lazy"
                      src={s.img}
                      alt={s.img}
                      key={index}
                      className=""
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <h1 className="title-section" id="ask">
                {selectedHotel.Hotel}
              </h1>
            </div>
            <img
              loading="lazy"
              src="/images/gps-location.svg"
              alt="tag map"
              id="tag"
              width={30}
            />
            <p className="destino">{selectedHotel.Destino}</p>
            <div className="container-hotel">
              <NavLink className="text-back" onClick={() => navigate(-1)}>
                <i className="fa-solid fa-arrow-left"></i>
                Volver a destinos
              </NavLink>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="line-red-hotel"></div>
                  <p className="justify" id="descripcion">
                    {selectedHotel.descripcion}
                  </p>
                  <section className="icons">
                    {selectedHotel.iconos_servicios?.map((icon, index) => (
                      <img
                        loading="lazy"
                        src={icon.img}
                        alt={icon.img}
                        key={index}
                      />
                    ))}
                  </section>
                  <button className="cta-hotel" onClick={SendEvent}>
                    <p>Reserva</p>
                    <p>{selectedHotel.noches ? "Y redime tus noches" : ""}</p>
                  </button>

                  <div className="container-info">
                    <section className="detalles">
                      <h4 className="title-policy fw-bold text-center">
                        DETALLES
                      </h4>
                      {selectedHotel.detalles?.map((detalle, index) => (
                        <div key={index}>
                          {Object.entries(detalle).map(([key, value]) => (
                            <p key={key} className="m-2">
                              <strong>{key}:</strong> {value}
                            </p>
                          ))}
                        </div>
                      ))}
                    </section>
                    <section className="alrededores">
                      <h4 className="title-policy fw-bold text-center">
                        ALREDEDORES
                      </h4>
                      {selectedHotel.alrededores?.map((icon, index) => (
                        <img
                          loading="lazy"
                          src={icon.img}
                          alt={icon.img}
                          key={index}
                          width={50}
                        />
                      ))}
                    </section>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-12 col-xl-12">
                      <section className="habitaciones">
                        <h4 className="title-policy fw-bold text-center">
                          Habitaciones
                        </h4>
                        <Swiper
                          modules={[Navigation, Autoplay, Pagination, A11y]}
                          navigation
                          slidesPerView={1}
                          spaceBetween={0}
                          loop={true}
                          direction="horizontal"
                          pagination={{ clickable: true }}
                          autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                          }}
                        >
                          {selectedHotel.habitaciones?.map((s, index) => (
                            <SwiperSlide className="" key={index}>
                              <img
                                loading="lazy"
                                src={s.img}
                                alt={s.img}
                                key={index}
                                className="custom-img"
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </section>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-12 col-xl-12">
                      <section className="map">
                        <iframe
                          src={selectedHotel.map}
                          width="520"
                          height="300"
                          title={selectedHotel.map}
                          style={{
                            border: 0,
                            borderRadius: ".5rem",
                          }}
                          allowFullScreen
                          loading="lazy"
                          referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Hotel;
