import React from "react";

const PolicyThree = () => {
  return (
    <>
      <img
        loading="lazy"
        src="/images/bio_seguridad.png"
        alt="bio seguridad"
        width={"100%"}
      />
      <div className="container-policy">
        <h1 className="title-policy text-center">Bio Seguridad</h1>
        <p className="fw-bold">
          Pensando en ti hemos diseñado las siguientes recomendaciones:
        </p>
        <p className="title-policy fw-bold">ANTES DEL VUELO</p>
        <p>
          Si presentas algún síntoma como fiebre, náuseas, dolor de cabeza,
          dolor corporal o dificultad al respirar, por tu seguridad y la de
          todos infórmanos para buscar alternativas de reprogramación de tu
          viaje para que nos acompañes en el destino cuando mejor te encuentres.
        </p>
        <p>
          Prepara tu kit de bioseguridad, tapabocas, guantes, gel antibacterial,
          entre otros elementos personales como tú propio lapicero. Alista los
          documentos exigidos para tu viaje, Recibirás con la reconfirmación de
          tú reserva por correo electrónico y mensaje de texto las
          recomendaciones y documentos de tu viaje.
        </p>
        <p>
          Check in online de tu vuelo: Recibirás por mensaje de texto el número
          de reserva de vuelo para que puedas realizar el check in online con tu
          aerolínea y recibir el pasabordo en caso de no realizarlo online
          podrás hacer el check in en los counter del aeropuerto de acuerdo a
          protocolos establecidos
        </p>
        <p>
          Check in online Hotel: Recibiras un correo electrónico confirmando tu
          reserva. Te invitamos a realizar el Web Check in ingresando a
          <a
            href="https://mireserva.onvacation.com "
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            https://mireserva.onvacation.com{" "}
          </a>
          , así proceso de registro en el hotel será más ágil y seguro.
          Restricciones en destino, verifica si hay alguna restricción o
          procedimiento que realizar definido por el destino a visitar. Para
          todos los destinos se recomienda descargar la aplicacion Coronapp en
          el celular ya que puede ser solicitado en Aeropuerto y facilita el
          monitoreo en tiempo real de datos recopilados al Centro de Operaciones
          de Emergencias del Instituto Nacional de Salud (INS), para que puedan
          actuar rápidamente y dar apoyo en coordinación con las autoridades
          locales, departamentales y nacionales.
        </p>
        <p>
          Si tu vuelo es internacional debes registrarte en Check-Mig: ingresar
          con 24 horas de anticipación a su viaje, y hasta una hora antes del
          cierre del vuelo, a la página web de Migración Colombia,
          www.migracioncolombia.gov.co
          <a
            href="https://apps.migracioncolombia.gov.co/pre-registro/public/preregistro.jsf"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            https://apps.migracioncolombia.gov.co/pre-registro/public/preregistro.jsf
          </a>{" "}
          ingresar la información que ahí se solicita y a vuelta de correo
          recibirá la confirmación de este proceso. Equipaje ligero. El equipaje
          de mano debe ser solo la cartera que pueda estar en el espacio de la
          parte baja del asiento delantero ya que los compartimientos no estarán
          habilitados.
        </p>
        <p>
          Llega con mayor anticipación, planea tu llegada al aeropuerto de
          acuerdo a tu aerolínea, cada una estipula tiempos específicos para su
          proceso más los implementados por el Aeropuerto debido a nuevos
          protocolos de seguridad, distanciamiento y chequeos de salud. Para
          vuelos nacionales si viajas en vuelo comerciales debes estar 2 horas
          antes de la salida del vuelo si viajas en vuelo charter o vuelos
          internacionales 3 horas antes de la salida del vuelo Evita
          acompañantes, al llegar al aeropuerto, recuerda que muchos aeropuertos
          tienen restricción de ingreso para acompañantes
        </p>
        <p className="fw-bold">EN EL AEROPUERTO</p>
        <p>
          Por tu seguridad, hacer uso obligatorio del tapabocas en todo momento,
          al ingresar al aeropuerto e incluso durante y después del vuelo.
          Conserva el distanciamiento social mínimo requerido por las
          autoridades (2 metros) y evita hacer uso de dinero en efectivo. El
          abordaje se realizará por grupos pequeños, manteniendo siempre el
          distanciamiento social requerido. Debes estar atento a los llamados en
          sala y solo acercarte a la zona de embarque cuando tu grupo sea
          mencionado.
        </p>
        <p className="fw-bold">DURANTE EL VUELO</p>
        <p>
          Uso tapabocas, debes usar tu tapabocas durante todo el vuelo. Ten en
          cuenta que, debes cambiar tu tapabocas cada 4 horas si es desechable o
          cada 8 horas si es de tela. Anuncios Aerolínea, recibirías información
          de medidas de seguridad y requisitos a cumplir durante el vuelo. Lleva
          tus elementos personales como audífonos, material de lectura, entre
          otros, para reducir la manipulación de elementos a bordo y evitar el
          contagio. Por disposiciones aeronáuticas, ,las tripulaciones deberán
          mantener distanciamiento social y hacer uso de elementos de protección
          personal, por lo que no podrán ayudarte a ubicar tu equipaje.
        </p>
        <p className="fw-bold">DESPUES DEL VUELO</p>
        <p>
          Conserva el distanciamiento social mínimo requerido por las
          autoridades (2 metros) al momento de recoger tu equipaje y recibir el
          llamado de pasajeros por parte del personal de recepción de Grupo
          Alianza Colombia en el aeropuerto para su orientación ya sea en el
          transporte interconexión hacia el hotel (según destino que aplique
          Amazonas y Guajira) y/o ubicación de zona de abordaje de taxis.
        </p>
        <p className="fw-bold">LLEGADA AL HOTEL</p>
        <p>
          Por tu seguridad, recomendamos hacer uso del tapabocas en todo momento
          y conserva el distanciamiento social mínimo requerido por las
          autoridades (2 metros) para el proceso de registro en el Check In.
        </p>
        <p>
          Registro Check In, el registro de check-in únicamente se hará por el
          titular de la reserva, mientras que los acompañantes se ubican de
          manera organizada en el lugar asignado para evitar aglomeraciones y se
          priorizará el registro para las reservas con personas vulnerables
          (mujeres embarazadas, niños menores de 5 años, adultos mayores y
          personas con movilidad reducida) manteniendo grupos acordes a la
          capacidad máxima de ocupación del área de recepción.
        </p>
        <p>
          Reporte de salud y zona de toma de temperatura, al ingreso de la
          recepción, se realizará una pequeña encuesta de salud y se realizará
          el control de toma de temperatura de cada miembro de la reserva.
        </p>
        <p>
          Zona de lavado y desinfección, al ingreso del hotel, contarás con
          estación de lavado y desinfección de manos, así como desinfección de
          tú equipaje al momento de tu llegada, y calzado para el acceso a la
          recepción.
        </p>
        <p>
          Comunicación constante, encontrarás infografías impresas y/o audios
          visuales digitales con todas las medidas de prevención de bioseguridad
          y requisitos a cumplir para disfrutas los servicios e instalaciones
          del hotel.
        </p>
        <p>
          Compromiso estado de salud, recomendamos hacer uso de la aplicación
          CoronApp con exposición del código QR para reportar su estado de salud
          y el de sus acompañantes acorde a las disposiciones nacionales y los
          números de teléfono de urgencias, servicios médicos y hospitales para
          asistencia o información sanitaria, en caso de que presentes síntomas
          como fiebre, náuseas, dolor de cabeza, dolor corporal o dificultad al
          respirar notifica de inmediato a Recepción.
        </p>
        <p>
          Servicio de alimentación, a tu llegada al hotel, se te informarán los
          horarios establecidos para tomar el servicio de alimentos organizado
          por grupos de reservas para controlar el aforo de los restaurantes.
        </p>
        <p>
          Servicio de bebidas, siguiendo la reactivación por sectores económicos
          emitidos por el Gobierno Nacional, el consumo de bebidas embriagantes
          se encuentra habilitado para bares y/o discotecas, según legislación
          local. Por lo tanto, esto es aplicable a los bares al interior de los
          hoteles. En los hoteles donde localmente se permita el expendio de
          bebidas embriagantes se contará con el servico de bar ( según
          categoría Hotel). En aquellos hoteles que la legislación local no lo
          permita contarás con puntos de hidratación de bebidas en estas mismas
          áreas.
        </p>
        <p>
          Aforo de ascensores, no olvides que el uso del ascensor se debe
          mantener distancia de dos (2) metros, por lo tanto, el aforo y
          servicio tiene prioridad para la población vulnerable (mujeres
          embarazadas, adultos mayores de 60 años y personal con movilidad
          reducida), o para el transporte de equipaje, y cuando no sea posible,
          el uso debe hacerse de manera individual.
        </p>
        <p>
          Servicios limitados, ten presente que, por normatividad nacional, se
          encuentra limitado el acceso a los servicios de piscina, jacuzzi y rio
          lento, y zonas húmedas (sauna – Hotel Girardot Resort) para tener
          acceso a este servicio debes hacer reserva en el hotel con el fin de
          garantizar los aforos establecidos.
        </p>
        <p>
          Política de Alojamiento para Huéspedes en Aislamiento, en caso de
          presentar algún síntoma asociado al Covid -19, debes recibir
          información de las condiciones de hospedaje en aislamiento una vez se
          defina su estadía, debido que no podrá desarrollar ningún tipo de
          actividad social fuera de la habitación asignada por el hotel,
          mientras no haya culminado su periodo de aislamiento.,
        </p>
        <p>
          Reporte de síntomas, con el propósito de cumplir con el numeral
          3.2.8.3 de la Resolución 1285 de 29 de Julio de 2020, recuerda que si
          estás al interior del Hotel y presentas síntomas de una enfermedad
          respiratoria o conoces a una persona al interior de las instalaciones
          que los tenga, puedes comunicarte inmediatamente con la recepción o
          hacérnoslo saber a través del correo sac@grupoalianzacolombia.com para
          adoptar las medidas de control que haya lugar.
        </p>
      </div>
    </>
  );
};

export default PolicyThree;
