import axios from "axios";

const mercadoPago = async (payload) => {
  const url =
    "https://priority-api-gac-production.up.railway.app/api/mercadopago/pagos-web";
  const response = await axios.post(url, payload);
  return response;
};

const getUserInfo = async (cedula) => {
  const url =
    "https://priority-api-gac-production.up.railway.app/api/users/user-payment";
  const { data } = await axios.post(url, cedula);
  return data;
};

const getValueMora = async (codigo) => {
  const url =
    "https://priority-api-gac-production.up.railway.app/api/users/payments-web";
  const { data } = await axios.post(url, { codigo: codigo });
  return data;
};
export { mercadoPago, getUserInfo, getValueMora };
