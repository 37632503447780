import React from "react";

const EthicalLine = () => {
  return (
    <section className="ContentEthicalLine">
      <h2 className="title-policy">Línea Ética</h2>
      <p>
        Grupo Alianza Colombia S.A.S. pone a disposición de sus funcionarios,
        clientes, proveedores, contratistas, accionistas y demás grupos de
        interés la Línea Ética, un canal receptor de denuncias con el propósito
        de incentivar el cumplimiento de estándares éticos, así como para
        prevenir potenciales eventos de corrupción, soborno, fraude, malas
        prácticas y situaciones irregulares al interior de nuestra organización.
      </p>
      <h3 className="title-policy mt-2">Denuncia:</h3>
      <p>
        <ul>
          <li>
            Uso inadecuado y apropiación indebida de bienes de la compañía
          </li>
          <li>
            Actos deliberados en contra de los valores y principios establecidos
            en el código de ética y el manual de transparencia y ética
            empresarial
          </li>
          <li>Violación de normas, políticas o procedimientos de la entidad</li>
          <li>
            Actos de extracción o revelación indebida de información
            privilegiada o restringida
          </li>
          <li>Actividades de corrupción y/o soborno</li>
          <li>Otras situaciones que se consideren contrarias a las normas</li>
        </ul>
      </p>
      <h3 className="title-policy mt-2">¿Cómo comunicarse?</h3>
      <p>
        Para comunicarse con la línea ética debe escribir a los siguientes
        medios de recepción de denuncias:
      </p>
      <p>
        <ul>
          {/* <li>WhatsApp: </li> */}
          <li>
            Correo electrónico:{" "}
            <a href="mailto:oficialdecumplimiento@grupoalianzacolombia.com">
              oficialdecumplimiento@grupoalianzacolombia.com
            </a>
          </li>
        </ul>
      </p>
      {/* <p>
        Denuncia: Por favor diligencia el siguiente formulario para radicar la
        denuncia.
      </p> */}
    </section>
  );
};

export default EthicalLine;
